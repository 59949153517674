const cookieScriptDomain = "";
const cookieScriptReadMore = "/cookies";
const cookieScriptDebug = 0;
const cookieScriptShowBadge=true;
const cookieScriptCurrentUrl = window.location.href;
const cookieScriptTitle = 'Hubbado uses cookies';
const cookieScriptDesc1 = 'We use cookies to provide you with the best possible service, and to continuously improve our website.';
const cookieScriptDesc2 = 'By clicking “OK” we will be able to personalise content for you. Click <a class="cookie-reject">No</a> to only place cookies for analytical purposes.';
const cookieScriptAccept = 'OK';
const cookieScriptMore = 'Your cookie settings';
const cookieScriptCopyrights = 'I agree';

var cookieScriptLoadJavaScript = function (d, b) {
  var c = document.getElementsByTagName("head")[0],
    a = document.createElement("script");
  a.type = "text/javascript";
  a.src = d;
  if (b !== undefined) {
    a.onload = a.onreadystatechange = function () {
      if (!a.readyState || /loaded|complete/.test(a.readyState)) {
        a.onload = a.onreadystatechange = null;
        c && a.parentNode && c.removeChild(a);
        a = undefined;
        b();
      }
    };
  }
  c.insertBefore(a, c.firstChild);
};

var InjectCookieScript = function () {
  let cookieScriptStatsDomain = window.location.protocol == "https:" ? "" : "chs03.";

  const cookieScriptCreateCookie = function (n, t, i) {
    var cookieScriptSecureCookie = window.location.protocol == "https:" ? ";secure" : "";
    var u = "", r, f;
    if (i) {
      r = new Date();
      r.setTime(r.getTime() + i * 864e5);
      u = "; expires=" + r.toGMTString();
    }
    f = cookieScriptDomain !== "" ? "; domain=" + cookieScriptDomain : "";
    document.cookie = n + "=" + t + u + f + "; path=/" + cookieScriptSecureCookie;
  };

  const cookieScriptReadCookie = function (n) {
    var r = n + "=",
      u = document.cookie.split(";"),
      t, i = 0;
    for (i = 0; i < u.length; i++) {
      t = u[i].trim();
      if (t.indexOf(r) == 0) return t.substring(r.length);
    }
    return null;
  };

  function addCookieBanner() {
    const existingBanner = document.getElementById("cookiescript_injected");
    if (existingBanner) existingBanner.remove();

    const cookieScriptAddBox = function () {
     const bannerHTML = `
       <div class="cookie-overlay"></div>
       <div id="cookiescript_injected">
         <div id="cookiescript_wrapper">
           <div class="info">
             <h1>${cookieScriptTitle}</h1>
             <p>${cookieScriptDesc1}</p>
             <p>${cookieScriptDesc2}</p>
           </div>
           <div class="cookie-buttons btn-container always-column">
             <div class="cookie-accept btn primary">${cookieScriptAccept}</div>
             <a id="cookiescript_readmore" class="modal-link cookie-modal btn" data-turbo="true" data-turbo-frame="modal" href="${cookieScriptReadMore}">${cookieScriptMore}</a>
           </div>
         </div>
       </div>
     `;
     document.body.insertAdjacentHTML('beforeend', bannerHTML);

     // Track events (Google Analytics, etc.)
     trackCookieEvent('Show');

     const injectedElement = document.getElementById("cookiescript_injected");
     if (injectedElement) injectedElement.style.display = 'block';
     const overlayElement = document.querySelector(".cookie-overlay");
     if (overlayElement) overlayElement.style.display = 'block';

     document.addEventListener('click', function(e) {
      const target = e.target;
      if(target.closest('#cookiescript_readmore') == null) return null;

      trackCookieEvent('Read More');
     });
    }

   const cookieScriptCurrentValue = cookieScriptReadVar("action");

   if(cookieScriptCurrentValue == "accept") {
    cookieScriptAllowJS();
   }
   if(cookieScriptCurrentValue == "accept" || cookieScriptCurrentValue == "reject") {
    cookieScriptShowBadgeFunction();
    return false;
   }
   cookieScriptAddBox();
  }

  function cookieScriptAcceptFunction() {
    const injectedElement = document.getElementById("cookiescript_injected");
    if (injectedElement) injectedElement.style.display = 'none';
    const overlayElement = document.querySelector(".cookie-overlay");
    if (overlayElement) overlayElement.style.display = 'none';

    cookieScriptAddVar("action", "accept");
    cookieScriptAllowJS();
    cookieScriptShowBadgeFunction();
  }

  function cookieScriptRejectFunction() {
    cookieScriptAddVar("action", "reject");
    cookieScriptClearAllCookies();

    const injectedElement = document.getElementById("cookiescript_injected");
    if (injectedElement) injectedElement.style.display = 'none';
    const overlayElement = document.querySelector(".cookie-overlay");
    if (overlayElement) overlayElement.style.display = 'none';
    cookieScriptShowBadgeFunction();
  }

  function cookieScriptAddVar(name, value) {
    const cookieConsentName = "CookieScriptConsent";
    let jsonCookie = JSON.parse(cookieScriptReadCookie(cookieConsentName) || '{}');
    jsonCookie[name] = value;
    document.cookie = cookieConsentName + "=" + JSON.stringify(jsonCookie) + "; path=/; max-age=2592000";
  }

  function cookieScriptAllowJS() {
    document.querySelectorAll('[data-cookiescript="accepted"]').forEach(function (el) {
      if (el.tagName === 'IMG') el.src = el.getAttribute('data-src');
      if (el.tagName === 'SCRIPT') {
        let script = document.createElement('script');
        script.src = el.getAttribute('src');
        document.body.appendChild(script);
      }
    });
  }

  function cookieScriptReadVar(name){
   var cookieConsentName="CookieScriptConsent";
   try {
    var jsonCookie=JSON.parse(cookieScriptReadCookie(cookieConsentName));
   } catch(e) {
    console.error(e);
    return null;
   }

   if(jsonCookie==null) {
    return null;
   } else { //todo Add is object
    return jsonCookie[name];
   }
  }

  function cookieScriptShowBadgeFunction() {
   return false;
  }

  document.addEventListener('click', function (e) {
    const target = e.target;
    if (target.closest('.cookie-accept')) {
      trackCookieEvent('Accept');
      cookieScriptAcceptFunction();
    } else if (target.closest('.cookie-reject')) {
      cookieScriptRejectFunction();
    }
  });

  document.addEventListener('DOMContentLoaded', function () {
    addCookieBanner();
  });
};

function trackCookieEvent(eventType) {
  if (window.ga) {
    ga('send', 'event', 'Cookie-Script', eventType, { nonInteraction: true });
  }
  if (window.gtag) {
    gtag('event', eventType, { event_category: 'Cookie-Script' });
  }
  if (window._gaq) {
    _gaq.push(['_trackEvent', 'Cookie-Script', 'Show', {'nonInteraction': 1}])
  }
}

function cookieScriptClearAllCookies() {
  var cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
  }
}

if (document.querySelector('body.test-environment') === null) {
  InjectCookieScript();
}
