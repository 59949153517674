import "./optimized_resize"
import Loader from "@/javascript/components/loader"
import isOverlapMoreThan50Percent from "@/javascript/components/tools/is_overlap_more_than_50_percent"

Loader.visual((content, resolve) => {
  resetCounters(content)
  resolve()
})

// TODO: Shouldn't the visual hooks be called on resize too?
window.addEventListener('optimizedResize', () => resetCounters(document))

const resetCounters = (content) => {
  hiddenCounter(content, '.job-category-list', '.job-category')
  // TODO: Should look for a generic skills list
  hiddenCounter(content, '.profile-overview-experience', '.skill-with-strength')
}

const hiddenCounter = (content, countElementsSelector, elementsToCountSelector) => {
  const containers = content.querySelectorAll(countElementsSelector)

  if(!containers) { return }

  containers.forEach((container) => {
    const countElms = container.querySelectorAll(elementsToCountSelector)
    const counter = container.querySelector('.counter')
    const otherContainer = container;

    if(!container || !countElms || !counter) {
      console.log('Warning: Could not find all elements necessary for counter');
      return
    }

    let hiddenElms = 0
    const containerBnds = container.getBoundingClientRect()
    const notVisibleClass = 'not-visible'

    countElms.forEach((elm) => {
      if(isOverlapMoreThan50Percent(containerBnds, elm.getBoundingClientRect())) {
        elm.classList.remove(notVisibleClass)
      } else {
        hiddenElms += 1
        elm.classList.add(notVisibleClass)
      }
    })

    if(hiddenElms > 0) {
      const visibleElms = container.querySelectorAll(elementsToCountSelector + ':not(.' + notVisibleClass + ')')

      if(visibleElms.length > 0) {
        const lastVisible = visibleElms[visibleElms.length - 1]
        const lastVisibleBnds = lastVisible.getBoundingClientRect()

        counter.style.left = ((lastVisibleBnds.left - containerBnds.left) + lastVisibleBnds.width) + 'px'
        counter.style.top = (lastVisibleBnds.top - containerBnds.top) + 'px'
        counter.style.bottom = 'initial'
        counter.style.right = 'initial'
        counter.innerHTML = '+' + hiddenElms
      }
    } else {
      counter.innerHTML = ''
    }
  })
}
