import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="msp-iframe"

export default class extends Controller {
  static values = {
    url: String,
    mspTitleSuffix: String,
    hubbadoTitleSuffix: String,
    companyId: String
  }

  resizeObserver = new ResizeObserver((entries) => {
    for (let entry of entries) {
      this.reportIframeHeight();
    }
  })

  connect() {
    if (window.mspFrameMessageBound) {
      return;
    }
    window.mspFrameMessageBound = true;
    window.addEventListener('message', (event) => {
      if (event.data.type === 'popstate') {
        this.popState(event);
      }
      if (event.data.type === 'reloadFrame') {
        this.reloadFrame(event.data.url, false);
      }
    });
    if (this.element.src !== this.urlValue) {
      this.element.src = this.urlValue
    }
    window.parent.postMessage({ type: 'msp-iframe:controllerLoaded' }, '*');
    this.resizeObserver.observe(document.body);
  }

  reloadFrame(url, pushState = true) {
    if(!pushState) {
      this.preventHistoryUpdate = url;
    }
    this.element.src = url;
  }

  beforeFetchRequest(e) {
    e.preventDefault()
    let fetchUrl = new URL(e.detail.url);
    fetchUrl.searchParams.set('managing_company_ids', this.companyIdValue);
    e.detail.url = fetchUrl;
    e.detail.resume()
  }

  turboFrameRender(e) {
    let fetchResponse = e.detail.fetchResponse;
    if (!fetchResponse.isHTML || !fetchResponse.response.ok) {
      return
    }
    fetchResponse.response.text().then((html) => {
      let parser = new DOMParser();
      let doc = parser.parseFromString(html, 'text/html');
      let title = doc.querySelector('title');
      let body = doc.querySelector('body');
      let userLoggedIn = doc.querySelector('meta[name=logged-in]');
      if (body) {
        this.updateBodyClasses(body.classList)
      }
      if(userLoggedIn){
        window.parent.postMessage({ type: 'navigate', url: fetchResponse.response.url }, '*');
        e.preventDefault();
      }
      if (title) {
        this.updatePageTitle(title.innerText)
      }
    })
  }

  updatePageTitle(title) {
    if (title.endsWith(this.hubbadoTitleSuffixValue)) {
      document.title = title.replace(this.hubbadoTitleSuffixValue, this.mspTitleSuffixValue);
    } else {
      document.title = title + this.mspTitleSuffixValue;
    }
    window.parent.postMessage({
      type: 'updatePageTitle',
      title: document.title
    }, '*');
  }

  updateBodyClasses(classList) {
    let body = document.querySelector('body');
    body.classList.remove(...body.classList);
    let newClassList = Array.from(classList);
    newClassList.push('embeded');
    newClassList.forEach((className) => {
      body.classList.add(className);
    });
  }

  updateHistory() {
    if(this.element.src === this.urlValue) {
      this.historyReplace();
    } else {
      this.historyPush();
    }
  }

  turboFrameLoad(e) {
    if (this.preventHistoryUpdate === this.element.src) {
      this.preventHistoryUpdate = null;
      return;
    }
    this.updateHistory();
  }

  turboFrameMissing(e) {
    window.parent.postMessage({ type: 'navigate', url: e.detail.response.url }, '*');
    e.preventDefault();
  }

  reportIframeHeight() {
    let body = document.body
    let height = Math.max(body.scrollHeight, body.offsetHeight);
    window.parent.postMessage({ type: 'resizeIframe', height: height }, '*');
  }

  historyReplace() {
    window.parent.postMessage({
      type: 'historyReplace',
      url: this.element.src
    }, '*');
  }

  historyPush() {
    window.parent.postMessage({
      type: 'historyPush',
      url: this.element.src
    }, '*');
  }

  popState(e) {
    let frameUrl = new URL(this.element.src);

    frameUrl.pathname = e.data.state.hubbadoUrl || new URL(this.urlValue).pathname;
    this.reloadFrame(frameUrl.href, false);
  }
}
