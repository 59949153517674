// Returns true if more that 50% of rect2 overlaps with rect1
export default function isOverlapMoreThan50Percent  (rect1, rect2) {
  // Calculate overlap bounds
  const overlapLeft = Math.max(rect1.left, rect2.left);
  const overlapRight = Math.min(rect1.right, rect2.right);
  const overlapTop = Math.max(rect1.top, rect2.top);
  const overlapBottom = Math.min(rect1.bottom, rect2.bottom);

  // Check if there's any overlap
  if (overlapRight <= overlapLeft || overlapBottom <= overlapTop) {
    return false; // No overlap
  }

  // Calculate overlap area
  const overlapArea = (overlapRight - overlapLeft) * (overlapBottom - overlapTop);

  // Calculate second rectangle's area
  const area2 = (rect2.right - rect2.left) * (rect2.bottom - rect2.top);

  // Check if overlap area is at least 50% of the second rectangle's area
  return overlapArea >= 0.5 * area2;
}
